import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {InputNumber, Select, Button as AntButton, message, Tooltip} from 'antd';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import * as Icon from '../../components/Icon';
import ModalContent from '../../components/ModalContent';
import ReserveCalendar from '../../components/ReserveCalendar';
import {
  LOGISTICE_TYPE,
  LOGISTICE_TYPE_CATEGORY,
  ADDITIONAL_TABLEWARE_PRICE,
  LOGISTICE_TYPE_DISPLAY,
  PRODUCT_TYPE,
  CUSTOM_CARD_LANG,
} from '../../domain/Constants';
import Button from '../../components/Button';
import * as ProductUtil from '../../domain/Product';
import {navigate} from 'gatsby-link';
import URL_PATH from '../../UrlPath';
import LogisticWarningModal from './LogisticWarningModal';
import SelfPickWarningModal from './SelfPickWarningModal';
import {formatDateStrToRepublicEra} from '../../utils/date';
import {
  PRODUCT_BUY_LIMIT,
  PRODUCT_ALLOW_BUY_TIME,
  PRODUCT_EXTRA_HINT,
} from '../../domain/ProductHardcodeConstraint';
import {
  isAllChineseCharacter,
  isAllEnglishCharacter,
} from '../../utils/validations';

import moment from 'moment';
import {themePrimary} from '../../domain/Theme';
import Gtag, {useViewed} from '../../utils/gtag';

const SELF_PICK_STORES = ['仁愛門市']; //['仁愛門市', '中和門市'];

function ProductConfigPanel(props) {
  const app = React.useContext(AppContext.Context);
  const [isAddingToCart, setIsAddingToCart] = React.useState(false);
  const [isGoingPurchase, setIsGoingPurchase] = React.useState(false);
  const {
    product,
    values,
    setValues,
    additionalPurchasePanelComp,
    isInAdditionalPanel = false,
  } = props;

  useViewed(() => {
    let _defaultSpecItem = product.spec[0];
    Gtag('event', 'view_item', {
      currency: 'TWD',
      value: _defaultSpecItem.price,
      items: [product].map((p) => ({
        item_id: p.id,
        item_name: p.name,
      })),
    });
  }, !product);

  const activeSpecItem = React.useMemo(() => {
    let _foundSpec = product.spec.find((s) => s.item_id === values.specItemId);
    return _foundSpec || null;
  }, [product, values.specItemId]);

  const _priceSummary = React.useMemo(() => {
    let _productTotal = activeSpecItem?.price || 0;

    return {
      productTotal: _productTotal,
      total: _productTotal * Math.ceil(values.quantity), // FIXME
    };
  }, [values.specItemId, values.additionalTablewaresQuantity, values.quantity]);

  const _openReserveCalendar = React.useCallback(() => {
    AppActions.setModal(
      <ReserveCalendarModal
        product={product}
        itemId={activeSpecItem.item_id}
        title={activeSpecItem.name}
        quantity={values.quantity}
        defaultSelectedDateStr={values.date}
        logisticType={
          values.logisticType === LOGISTICE_TYPE.selfPick
            ? LOGISTICE_TYPE_CATEGORY.selfPick
            : LOGISTICE_TYPE_CATEGORY.home
        }
        onSelect={(dateStr, calendarData) => {
          setValues((_values) => ({
            ..._values,
            date: dateStr,
            entpStockId: calendarData.entp_stock_id,
          }));
          AppActions.setModal(null);
        }}
      />,
    );
  }, [
    activeSpecItem,
    values.logisticType,
    values.quantity,
    values.date,
    product,
  ]);

  React.useEffect(() => {
    if (product && !values.specItemId) {
      if (product.spec.length === 0) {
        return;
      }
      let _defaultSpecItem = product.spec[0];
      setValues((_values) => ({
        ..._values,
        specItemId: _defaultSpecItem.item_id,
      }));
    }
  }, [product, setValues, values]);

  const _onSpecSelect = React.useCallback(
    (value) => {
      setValues((_values) => ({
        ..._values,
        specItemId: value,
        pickUpTime: '',
        date: '',
        entpStockId: '',
      }));
    },
    [product],
  );

  React.useEffect(() => {
    let value = values.logisticType;
    if (!isInAdditionalPanel) {
      if (value !== LOGISTICE_TYPE_CATEGORY.selfPick) {
        AppActions.setModal(
          <LogisticWarningModal onClose={() => AppActions.setModal(null)} />,
        );
      } else {
        AppActions.setModal(
          <SelfPickWarningModal onClose={() => AppActions.setModal(null)} />,
        );
      }
    }
  }, [values.logisticType, isInAdditionalPanel]);

  const _onLogisticSelect = React.useCallback(
    (value) => {
      setValues((_values) => ({
        ..._values,
        logisticType: value,
        pickUpTime: '',
        date: '',
        entpStockId: '',
      }));
    },
    [values],
  );

  const _onValueChange = React.useCallback(
    (field) => {
      return (value) => {
        let _nextValues = {
          [field]: value,
        };
        if (field === 'quantity') {
          _nextValues.date = '';
          _nextValues.entpStockId = '';
          _nextValues[field] = value;
        }
        setValues((_values) => ({
          ..._values,
          ..._nextValues,
        }));
      };
    },
    [values],
  );

  const _addItemToCart = React.useCallback(async () => {
    let result = false;

    if (values.selectedCardLang === CUSTOM_CARD_LANG.zh) {
      if (!isAllChineseCharacter(values.customCardInput)) {
        message.error(
          '請確認特牌文字是否符合規定(僅可使用中英文字元，不接受emoji或圖案)',
        );
        return;
      }
    } else if (values.selectedCardLang === CUSTOM_CARD_LANG.en) {
      if (!isAllEnglishCharacter(values.customCardInput)) {
        message.error(
          '請確認特牌文字是否符合規定(僅可使用中英文字元，不接受emoji或圖案)',
        );
        return;
      }
    }
    if (!app.profile) {
      alert('請先登入後再購買！');
      return;
    }
    if (!product.published) {
      alert('此商品目前不開放購買！');
      return;
    }
    if (!values.date) {
      alert('請選擇取貨/到貨日期！');
      return result;
    }
    if (!values.pickUpTime) {
      alert('請選擇取貨/到貨時段！');
      return result;
    }
    if (!Number.isInteger(values.quantity)) {
      alert('商品數量需為整數！');
      return;
    }

    // some special product has hardcoded-buy-time
    if (PRODUCT_ALLOW_BUY_TIME[product.id]) {
      let startTime = PRODUCT_ALLOW_BUY_TIME[product.id].start;
      let endTime = PRODUCT_ALLOW_BUY_TIME[product.id].end;
      let now = moment();
      if (startTime && now < moment(startTime)) {
        message.warn('此商品尚未開放購買');
        return;
      }
      if (endTime && now > moment(endTime)) {
        message.warn('此商品已不開放購買');
        return;
      }
    }

    // check product count with items in cart
    let _sameProductsInCart = app.cart
      ? (app.cart.items || []).filter((item) => item.product.id === product.id)
      : [];
    let _totalCountInCart = _sameProductsInCart.reduce(
      (acc, curr, idx) => acc + curr.config.qty,
      0,
    );

    // some special product has hardcoded-buy-limit
    if (
      PRODUCT_BUY_LIMIT[product.id] &&
      _totalCountInCart + values.quantity > PRODUCT_BUY_LIMIT[product.id].limit
    ) {
      message.error(PRODUCT_BUY_LIMIT[product.id].hint);
      return;
    }

    // enterprise product has min/max buy limit
    if (product.type === PRODUCT_TYPE.enterprise) {
      const productMinBuyLimit = product.enterprise_product_min_buy;
      const productMaxBuyLimit = product.enterprise_product_max_buy;
      if (
        productMinBuyLimit &&
        _totalCountInCart + values.quantity < productMinBuyLimit
      ) {
        message.error(`此商品每一訂單最小購買數為 ${productMinBuyLimit}。`);
        return;
      }

      if (
        productMaxBuyLimit &&
        _totalCountInCart + values.quantity > productMaxBuyLimit
      ) {
        message.error(
          `此商品每一訂單限量為 ${productMaxBuyLimit}。重新下單可以再訂。`,
        );
        return;
      }

      // enterprise product also need to check total entp stock quantity
      try {
        let entpStock = await app.actions.fetchEntpStockById(
          values.entpStockId,
        );
        if (entpStock.total_stock < _totalCountInCart + values.quantity) {
          message.error(
            `此商品總剩餘量不足，單筆訂單至多${entpStock.total_stock}`,
          );
          return;
        }
      } catch (err) {
        if (err.error === 'object_not_found') {
          message.error(
            `系統無法取得此商品總庫存量(${values.entpStockId})，請聯繫客服`,
          );
          return;
        }
        // cannot get entp stock, let user pass this now, will do the check when checkout
      }
    }
    let specHit = product.spec.find(
      (item) => item.item_id === values.specItemId,
    );
    try {
      try {
        const price = product.spec.find((s) => s.item_id === values.specItemId)
          .price;
        Gtag('event', 'add_to_cart', {
          currency: 'TWD',
          value: price * values.quantity,
          items: [product].map((p) => ({
            item_id: p.id,
            item_name: p.name,
          })),
        });
      } catch (e) {
        console.log('gtag', e);
      }

      function validateSelfPickStore() {
        if (values.logisticType !== 'self_pick') {
          return true;
        }

        const currSelfPickStore = product.self_pick_location
          ? '快閃店'
          : values.selfPickStore || SELF_PICK_STORES[0];

        for (const item of app.cart?.items || []) {
          if (currSelfPickStore === '快閃店') {
            if (item.config.extra?.self_pick_store) {
              return false;
            }
          } else {
            if (item.config.extra?.self_pick_store !== currSelfPickStore) {
              return false;
            }
          }
        }

        return true;
      }

      if (!validateSelfPickStore()) {
        message.error(`偵測到不同的自取店家, 請移除購物車內自取店家不同的品項`);
        return;
      }

      const extra =
        values.logisticType === 'self_pick' && !product.self_pick_location
          ? {
              self_pick_store: values.selfPickStore || SELF_PICK_STORES[0],
            }
          : {};

      await app.actions.addItemToCart({
        productId: product.id,
        quantity: values.quantity,
        specItemId: values.specItemId,
        date: values.date,
        customCardInput: values.customCardInput || '',
        pickUpTime: values.pickUpTime,
        logisticType: values.logisticType,
        entpStockId: values.entpStockId,
        extra,
      });

      result = true;
      message.success('商品已加入購物車！');
    } catch (err) {
      if (err.error === 'invalid_delivery_date') {
        let str = err.detail;
        // valid: 2021-05-10
        // let [_, date] = str.split('valid: ');
        alert(
          `請確認購物車內希望配送/自取日為同一日，若不同日期請分開下單，謝謝！`,
        );
      } else if (err.error === 'invalid_pick_time') {
        alert(`請確認購物車內取貨時段相同，若不同時段請分開下單，謝謝！`);
      } else if (err.error === 'invalid_logistic_type') {
        alert(
          `請確認購物車內配送方式是否相同，選購產品配送方式（冷藏/冷凍/自取）不同，需分批下單，謝謝！`,
        );
      } else if (err.error === 'invalid_box_spec') {
        alert(`此商品材積設定有誤！`);
      } else if (err.error === 'stock_not_enough') {
        alert(`此商品庫存不足！`);
      } else if (err.error === 'invalid_entp_purchase_quantity') {
        // FE will check first, then let BE check again
        alert(`不符商品購買上下限規定！`);
      } else {
        alert('加入購物車失敗！');
      }
    }
    return result;
  }, [values, app.profile, app.cart]);

  const _onAddToCart = React.useCallback(async () => {
    setIsAddingToCart(true);
    await _addItemToCart();
    setIsAddingToCart(false);
  }, [_addItemToCart]);

  const _onGoPurchase = React.useCallback(async () => {
    setIsGoingPurchase(true);
    let result = await _addItemToCart();
    setIsGoingPurchase(false);
    if (result) {
      navigate(URL_PATH.cart);
    }
  }, [_addItemToCart]);

  const productQtyHintText = React.useMemo(() => {
    if (product.type !== PRODUCT_TYPE.enterprise) {
      return '';
    }

    let result = '';
    if (
      product.enterprise_product_min_buy ||
      product.enterprise_product_max_buy
    ) {
      result += '單筆訂單購買數';
    }
    if (product.enterprise_product_min_buy) {
      result += `最少為${product.enterprise_product_min_buy}`;
    }
    if (product.enterprise_product_max_buy) {
      result += `${product.enterprise_product_min_buy ? '，' : ''}至多${
        product.enterprise_product_max_buy
      }`;
    }

    return result;
  }, [product]);

  const copyProductShareUrl = async () => {
    let text = app.actions.getProductShareLink(product);
    try {
      Gtag('event', 'share', {
        method: 'url',
        content_type: 'product',
        item_id: product.id,
        item_name: product.name,
      });
      await navigator.clipboard.writeText(text);
      message.success(`已複製連結`);
    } catch (err) {
      message.warn(`無法複製連結，建議更新瀏覽器或使用 Chrome`);
    }
  };

  const logiSelectStyle =
    product.allow_logistic_type.find((x) => x === LOGISTICE_TYPE.selfPick) &&
    product.self_pick_location
      ? {width: 200}
      : {width: 120};

  return (
    <Wrapper>
      <h1>
        {product.name}
        <Tooltip title="複製分享連結">
          <AntButton
            type="text"
            icon={<Icon.ShareAltOutlined size={24} color={themePrimary} />}
            style={{marginLeft: 10}}
            onClick={copyProductShareUrl}
          />
        </Tooltip>
      </h1>
      <span className="hint">{product.hint}</span>
      <p>{product.description}</p>

      <div className="config container">
        <div className="item">
          <label>選擇規格</label>
          <Select
            value={activeSpecItem ? activeSpecItem.item_id : null}
            style={{width: 120}}
            onChange={_onSpecSelect}>
            {product.spec.slice().map((s, idx) => (
              <Select.Option key={idx} value={s.item_id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
          <span className="hint">請先選擇規格，確認可訂購日期</span>
        </div>
        <div className="item">
          <label>訂購數量</label>
          <InputNumber
            style={{width: 120}}
            min={1}
            step={1}
            value={values.quantity}
            onChange={_onValueChange('quantity')}
          />
          {productQtyHintText && (
            <span className="hint">{productQtyHintText}</span>
          )}
          {!Number.isInteger(values.quantity) && (
            <span className="hint" style={{color: 'var(--color-red)'}}>
              請輸入整數
            </span>
          )}
        </div>
        <div className="item">
          <label>取貨方式</label>
          <Select
            placeholder="請選擇"
            value={values.logisticType}
            style={logiSelectStyle}
            onChange={_onLogisticSelect}>
            {product.allow_logistic_type.map((type, idx) => {
              const display = LOGISTICE_TYPE_DISPLAY[type];
              const displayHint =
                type === 'self_pick' && product.self_pick_location
                  ? `-${product.self_pick_location}`
                  : '';
              return (
                <Select.Option value={type} key={idx}>
                  {display + displayHint}
                </Select.Option>
              );
            })}
          </Select>
        </div>

        {values.logisticType === 'self_pick' && !product.self_pick_location && (
          <div className="item">
            <label>自取門市</label>
            <Select
              placeholder="請選擇"
              value={values.selfPickStore}
              defaultValue={SELF_PICK_STORES[0]}
              style={{width: 120}}
              onChange={(selfPickStore) =>
                setValues({...values, selfPickStore})
              }>
              {SELF_PICK_STORES.map((val) => {
                return (
                  <Select.Option value={val} key={val}>
                    {val}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        )}

        <div className="item">
          <label>選擇日期</label>
          <AntButton
            onClick={_openReserveCalendar}
            disabled={!values.logisticType}>
            {values.date
              ? formatDateStrToRepublicEra(values.date)
              : `請選擇${
                  values.logisticType === LOGISTICE_TYPE.selfPick
                    ? '自取'
                    : '到貨'
                }日期`}
          </AntButton>
          {PRODUCT_EXTRA_HINT[product.id] && (
            <span className="hint" style={{color: 'var(--color-red)'}}>
              {PRODUCT_EXTRA_HINT[product.id].message}
            </span>
          )}
        </div>

        <div className="item">
          <label>選擇時段</label>
          <Select
            value={values.pickUpTime || undefined}
            disabled={!values.logisticType}
            placeholder={`請選擇${
              values.logisticType === LOGISTICE_TYPE.selfPick ? '自取' : '到貨'
            }時段`}
            dropdownMatchSelectWidth
            onChange={_onValueChange('pickUpTime')}>
            {product[
              values.logisticType === LOGISTICE_TYPE.selfPick
                ? 'available_times_self_pick'
                : 'available_times_home'
            ].map((timeDisplay, idx) => (
              <Select.Option key={idx} value={timeDisplay}>
                {timeDisplay}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>

      {!!additionalPurchasePanelComp && (
        <div className="">{additionalPurchasePanelComp}</div>
      )}

      <div className="summary container">
        <div className="item">
          <label>商品金額</label>
          <p>{_priceSummary.productTotal} 元</p>
        </div>
      </div>

      <div className="total container">
        <div className="item">
          <label>金額總計</label>
          <p className="price">
            {_priceSummary.total}
            <span>元</span>
          </p>
        </div>
      </div>

      <div
        className="actions-wrapper"
        style={{paddingBottom: isInAdditionalPanel ? 0 : 50}}>
        <Button
          shadow
          disabled={!product.published}
          onClick={_onGoPurchase}
          loading={isGoingPurchase}
          style={{
            marginRight: 12,
            width: 'calc((100% - 12px)/2)',
            visibility: isInAdditionalPanel ? 'hidden' : 'visible',
          }}>
          立即購買
        </Button>
        <Button
          type="secondary"
          // disabled={!product.published} // FIXME: secondary button disabled ui is ugly
          onClick={_onAddToCart}
          loading={isAddingToCart}
          style={{width: 'calc((100% - 12px)/2)'}}>
          加入購物車
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > h1 {
    font-size: 34px;
    color: #595757;
    letter-spacing: 0;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  & > span.hint {
    font-size: 14px;
    color: #595757;
    display: block;
    margin-bottom: 20px;
  }

  & > p {
    font-size: 14px;
    color: #595757;
    letter-spacing: 0;
    padding-top: 10px;
    padding-bottom: 30px;
    border-top: 1px solid #e5e5ea;
    border-bottom: 1px solid #e5e5ea;
    white-space: pre-wrap;
  }

  & > .container {
    & > .item:not(:last-child) {
      margin-bottom: 15px;
    }
    & > .item {
      display: flex;
      align-items: center;
      & > .hint {
        color: grey;
        font-size: 12px;
        margin-left: 5px;
      }
      & > label {
        font-size: 14px;
        color: #595757;
        font-weight: bold;
        margin-right: 36px;
      }
      & > p.price {
        font-size: 30px;
        color: #b79d7a;
        line-height: 45px;
        font-weight: bold;

        & span {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  & > .config.container {
    padding: 15px 0px;
    border-bottom: 1px solid #e5e5ea;
  }

  & > .summary.container {
    padding: 15px 0px;
    border-bottom: 1px solid #e5e5ea;
  }

  & > .total.container {
    padding: 15px 0px 66px;
  }

  & > .actions-wrapper {
  }
`;

function ReserveCalendarModal(props) {
  const {
    itemId,
    title,
    quantity,
    logisticType,
    onSelect,
    defaultSelectedDateStr = null,
    product,
  } = props;

  return (
    <ModalContent
      title={`可訂購日期預覽（${
        logisticType === LOGISTICE_TYPE_CATEGORY.selfPick
          ? '自取日'
          : '希望到貨日'
      }）`}>
      <ReserveCalendar
        item={{id: itemId, title, logisticType, quantity}}
        onSelect={onSelect}
        defaultSelectedDateStr={defaultSelectedDateStr}
        product={product}
      />
    </ModalContent>
  );
}

export default ProductConfigPanel;
