import React from 'react';
import {Modal} from 'antd';
import ProductConfigPanel from './ProductConfigPanel';

const initialValues = {
  specItemId: null,
  logisticType: undefined,
  quantity: 1,
  date: '',
  pickUpTime: '',
};

function RecommendProductModal(props) {
  const {product, visible = false, onCancel = () => 0} = props;
  const [values, setValues] = React.useState(initialValues);

  const _onCancel = () => {
    setValues(initialValues);
    onCancel();
  };

  if (!product) {
    return null;
  }

  return (
    <Modal
      closable={true}
      onCancel={_onCancel}
      zIndex={11}
      title={`加購${product.name}`}
      visible={visible}
      footer={null}>
      <ProductConfigPanel
        key={product}
        product={product}
        values={values}
        setValues={setValues}
        additionalPurchasePanelComp={null}
        isInAdditionalPanel={true}
      />
    </Modal>
  );
}

export default RecommendProductModal;
