import React from 'react';
import styled from 'styled-components';
import {Input, Modal} from 'antd';
import * as AppContext from '../../AppContext';
import FixedRatioImage from '../../components/FixedRatioImage';
import * as ProductUtil from '../../domain/Product';
import CheckButton from '../../components/CheckButton';
import {CUSTOM_CARD_LANG} from '../../domain/Constants';
import ProductItem from '../../components/ProductItem';
import RecommendProductModal from './RecommendProductModal';

function ProductAdditionalPurchasePanel(props) {
  const app = React.useContext(AppContext.Context);
  const {product, values, setValues, showSectionTitle} = props;
  const [
    selectedRecommendProduct,
    setSelectedRecommendProduct,
  ] = React.useState(null);
  const [customCardData, setCustomCardData] = React.useState({
    selectedCardLang: values.selectedCardLang,
    zhInputValue: '',
    enInputValue: '',
  });

  const _additionalProducts = React.useMemo(() => {
    return app.products
      .filter(ProductUtil.isPublishedProduct)
      .filter(ProductUtil.isValidAdditionalProduct)
      .filter((p) =>
        ProductUtil.isRecommendAdditionalProductForThisItem(product, p.id),
      );
  }, [app.products, product]);

  const _onRecommendProductItemClick = React.useCallback((recommend) => {
    setSelectedRecommendProduct(recommend);
  }, []);

  const _onCustomCardTypeSelect = React.useCallback(
    (type) => {
      const _currentEditingField =
        type === CUSTOM_CARD_LANG.zh ? 'zhInputValue' : 'enInputValue';

      setCustomCardData({
        ...customCardData,
        zhInputValue: '',
        enInputValue: '',
        [_currentEditingField]: customCardData[_currentEditingField],
        selectedCardLang: type,
      });
      setValues((_values) => ({
        ..._values,
        customCardInput: customCardData[_currentEditingField],
        selectedCardLang: type,
      }));
    },
    [customCardData],
  );

  const _onCustomCardValueChange = React.useCallback(
    (type) => {
      return (e) => {
        const _currentEditingField =
          type === CUSTOM_CARD_LANG.zh ? 'zhInputValue' : 'enInputValue';
        let _nextValue = e.target.value;

        setCustomCardData({
          ...customCardData,
          zhInputValue: '',
          enInputValue: '',
          selectedCardLang: type,
          [_currentEditingField]: _nextValue,
        });

        setValues((_values) => ({
          ..._values,
          customCardInput: _nextValue,
          selectedCardLang: type,
        }));
      };
    },
    [values, customCardData],
  );

  return (
    <Wrapper showSectionTitle={showSectionTitle}>
      {showSectionTitle &&
        (product.allow_additional_custom_card ||
          _additionalProducts.length > 0) && <h3>商品加購區</h3>}
      <div className="additional-purchase container">
        {product.allow_additional_custom_card && (
          <>
            <div className="item" style={{marginBottom: 0}}>
              <label>特牌客製</label>
              <div className="custom-card-configs">
                <div className="row" style={{marginBottom: 15}}>
                  <CheckButton
                    checked={
                      customCardData.selectedCardLang === CUSTOM_CARD_LANG.zh
                    }
                    onClick={() => _onCustomCardTypeSelect(CUSTOM_CARD_LANG.zh)}
                  />
                  <div
                    className="option-title"
                    onClick={() =>
                      _onCustomCardTypeSelect(CUSTOM_CARD_LANG.zh)
                    }>
                    中文
                  </div>
                  <Input
                    value={customCardData.zhInputValue}
                    placeholder="限8個中文字元"
                    onFocus={_onCustomCardValueChange(CUSTOM_CARD_LANG.zh)}
                    onChange={_onCustomCardValueChange(CUSTOM_CARD_LANG.zh)}
                    maxLength={8}
                  />
                </div>
                <div className="row">
                  <CheckButton
                    checked={
                      customCardData.selectedCardLang === CUSTOM_CARD_LANG.en
                    }
                    onClick={() => _onCustomCardTypeSelect(CUSTOM_CARD_LANG.en)}
                  />
                  <div
                    className="option-title"
                    onClick={() =>
                      _onCustomCardTypeSelect(CUSTOM_CARD_LANG.en)
                    }>
                    英文
                  </div>
                  <Input
                    value={customCardData.enInputValue}
                    placeholder="限20個英文字元"
                    onFocus={_onCustomCardValueChange(CUSTOM_CARD_LANG.en)}
                    onChange={_onCustomCardValueChange(CUSTOM_CARD_LANG.en)}
                    maxLength={20}
                  />
                </div>
              </div>
            </div>
            <p className="hint" style={{marginTop: 5, marginBottom: 20}}>
              加購特牌服務時，若購買同一項商品，請分開加入購物車，以利分辨客製項目
            </p>
          </>
        )}

        {_additionalProducts.length > 0 && (
          <div className="additional-items">
            {_additionalProducts.map((ap, idx) => (
              <ProductItem
                key={idx}
                item={ap}
                onItemClick={_onRecommendProductItemClick}
                style={{
                  marginLeft: idx === 0 ? 0 : 20,
                  maxWidth: 162,
                  minWidth: 162,
                  cursor: 'pointer',
                }}
              />
            ))}
          </div>
        )}
      </div>

      <RecommendProductModal
        product={selectedRecommendProduct}
        visible={!!selectedRecommendProduct}
        onCancel={() => setSelectedRecommendProduct(null)}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > h3 {
    font-size: 22px;
    line-height: 20px;
    color: #b79d7a;
    margin-bottom: 12px;
    text-align: center;
  }

  & .hint {
    color: grey;
    font-size: 12px;
  }

  & .custom-card-configs {
    & > .row {
      display: flex;
      align-items: center;
      & > .option-title {
        margin-right: 32px;
        margin-left: 5px;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }

  & .additional-items {
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
  }

  & > .container {
    padding: 15px 0px;
    & > .item:not(:last-child) {
      margin-bottom: 15px;
    }
    & > .item {
      display: flex;
      align-items: center;
      & > .hint {
        font-size: 16px;
        color: #cccccc;
        letter-spacing: 0;
        margin-left: 15px;
      }
      & > label {
        font-size: 14px;
        color: #595757;
        font-weight: bold;
        margin-right: 36px;
      }
      & > p.price {
        font-size: 30px;
        color: #b79d7a;
        line-height: 45px;
        font-weight: bold;

        & span {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  & > .additional-purchase.container {
    ${(props) =>
      props.showSectionTitle ? 'border-top: 1px solid #e5e5ea;' : ''}
  }
`;

export default ProductAdditionalPurchasePanel;
