function isAllChineseCharacter(str) {
  const reg = /^([\p{Script=Han}])*$/u;
  return reg.test(str);
}
function isAllEnglishCharacter(str) {
  const reg = /^[\w.-\s]{0,20}$/;
  return reg.test(str);
}

export {isAllChineseCharacter, isAllEnglishCharacter};
