const PRODUCT_BUY_LIMIT = {
  // prod
  '60efe5d63f1dbcd1925fa111': {
    limit: 5,
    hint: '此商品每一訂單限量5盒。重新下單可以再訂。',
  },
  '60eeaf6007a27fe203cf1538': {
    limit: 5,
    hint: '此商品每一訂單限量5盒。重新下單可以再訂。',
  },
  // stg
  '6135e5243398cd88daf07f19': {
    limit: 5,
    hint: '此商品每一訂單限量5盒。重新下單可以再訂。',
  },
};

const PRODUCT_ALLOW_BUY_TIME = {
  // prod
  '60eff5981148ae146a94f634': {
    // 11/22 12:00
    start: '2021-11-22T04:00:00.306Z',
    end: '2022-11-22T04:00:00.306Z',
  },
  '60eff39f3f1dbcd1925fa113': {
    start: '2021-11-22T04:00:00.306Z',
    end: '2022-11-22T04:00:00.306Z',
  },
  '61825a0ebeff31a16c79e14b': {
    start: '2021-11-22T04:00:00.306Z',
    end: '2022-11-22T04:00:00.306Z',
  },
  '60eeaf6007a27fe203cf1538': {
    start: '2021-11-22T04:00:00.306Z',
    end: '2022-11-22T04:00:00.306Z',
  },
  '60efe5d63f1dbcd1925fa111': {
    start: '2021-11-22T04:00:00.306Z', // 12:00
    end: '2022-11-22T04:00:00.306Z',
  },
  // stg
  '6135e5243398cd88daf07f19': {
    start: '2021-11-17T04:30:00.306Z',
    end: '2022-11-22T04:00:00.306Z',
  },
};

const PRODUCT_EXTRA_HINT = {
  '60eff5981148ae146a94f634': {
    message:
      '春節禮盒為客製化限量商品，故訂單一經成立則無法取消訂單或更換其他商品及變動取貨日期',
  },
  '60eff39f3f1dbcd1925fa113': {
    message:
      '春節禮盒為客製化限量商品，故訂單一經成立則無法取消訂單或更換其他商品及變動取貨日期',
  },
  '61825a0ebeff31a16c79e14b': {
    message:
      '春節禮盒為客製化限量商品，故訂單一經成立則無法取消訂單或更換其他商品及變動取貨日期',
  },
  '60eeaf6007a27fe203cf1538': {
    message:
      '春節禮盒為客製化限量商品，故訂單一經成立則無法取消訂單或更換其他商品及變動取貨日期',
  },
  '60efe5d63f1dbcd1925fa111': {
    message:
      '春節禮盒為客製化限量商品，故訂單一經成立則無法取消訂單或更換其他商品及變動取貨日期',
  },
  // stg
  '6135e5243398cd88daf07f19': {
    message:
      '春節禮盒為客製化限量商品，故訂單一經成立則無法取消訂單或更換其他商品及變動取貨日期',
  },
};

export {PRODUCT_BUY_LIMIT, PRODUCT_ALLOW_BUY_TIME, PRODUCT_EXTRA_HINT};
