import React from 'react';
import ModalContent from '../../components/ModalContent';
import styled from 'styled-components';
import Button from '../../components/Button';

function SelfPickWarningModal(props) {
  const {onClose = () => 0} = props;
  return (
    <ModalContent title={'自取說明提醒'}>
      <ContentWrapper>
        <p>
          {`①11:30-13:00為熱門時段，訂單取貨人數眾多，要請您排隊等候取貨，建議延後選擇取貨時段。
②若購買多項商品，自取時段請選擇同日同一時間提貨，恕不提供分開取貨。若選擇不同時間，將以第一個加入購物車的商品選擇的時間為準。
      `}
        </p>
        <div className="modal-footer">
          <Button type="secondary" size={'small'} onClick={onClose}>
            我知道了
          </Button>
        </div>
      </ContentWrapper>
    </ModalContent>
  );
}

const ContentWrapper = styled.div`
  & > p {
    white-space: pre-line;
  }

  & > .modal-footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`;

export default SelfPickWarningModal;
