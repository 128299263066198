import React from 'react';
import ModalContent from '../../components/ModalContent';
import styled from 'styled-components';
import Button from '../../components/Button';

function LogisticWarningModal(props) {
  const {onClose = () => 0} = props;
  return (
    <ModalContent title={'黑貓宅配說明'}>
      <ContentWrapper>
        <p>
          ① 黑貓宅急便週日不收、送貨
          <br />
          ② 黑貓宅急便送貨量過多時，無法指定到貨日，有可能延遲二到三日才會到貨。
          <br />
          ③
          因花蓮地區地震災害影響，目前有延遲的狀況發生，可能會延遲2-3天到貨，且無法指定配送時間，亦可能不會當日寄出，隔日就收到貨，寄出後送達日期及時間皆由黑貓宅配安排。
          <br />
          例如：宅配到貨日選擇9/7，法朋將會安排貨物於9/6寄出，但可能9/8或9/9才會到貨。
          <br />
          ④
          因黑貓宅急便物流量非常大，蛋糕可能發生不可避免的碰撞，會有位移的風險，若無法接受請勿選擇宅配呦！
          <br />
          ⑤
          若蛋糕有嚴重毀損情形請第一時間拍照並回傳至LINE客服@351buhar，客服將會依訊息順序回覆您。
          <br />
        </p>
        <div className="modal-footer">
          <Button type="secondary" size={'small'} onClick={onClose}>
            我知道了
          </Button>
        </div>
      </ContentWrapper>
    </ModalContent>
  );
}

const ContentWrapper = styled.div`
  & > p {
    white-space: pre-line;
  }

  & > .modal-footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`;

export default LogisticWarningModal;
