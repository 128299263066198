import React from 'react';
import styled from 'styled-components';

function CheckButton(props) {
  const {checked} = props;
  return (
    <CheckButtonWrapper checked={checked} {...props}>
      <div className="icon">
        <div className="checked" />
      </div>
    </CheckButtonWrapper>
  );
}

const CheckButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  & > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border: 2px solid #e5e5ea;
    border-radius: 5px;

    & > .checked {
      width: ${(props) => (props.checked ? 12 : 0)}px;
      height: ${(props) => (props.checked ? 12 : 0)}px;
      background-color: ${(props) =>
        props.checked ? 'var(--theme-primary)' : '#fff'};
      transition: 200ms;
      border-radius: 2px;
    }
  }
`;

export default CheckButton;
