import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import ProductConfigPanel from './ProductConfigPanel';
import ProductImageCarousel from '../../components/ImageCarousel';
import useDimension from '../../hooks/use-dimension';
import {CUSTOM_CARD_LANG} from '../../domain/Constants';
import ProductAdditionalPurchasePanel from './ProductAdditionalPurchasePanel';
import ProductInfoTabs from './ProductInfoTabs';
import queryString from 'query-string';
import * as ProductUtil from '../../domain/Product';
import {Button, Result} from 'antd';
import {navigate} from 'gatsby';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
function ProductPage(props) {
  const [product, setProduct] = React.useState(null);
  const [values, setValues] = React.useState({
    specItemId: null,
    logisticType: undefined,
    quantity: 1,
    date: '',
    pickUpTime: '',
    customCardInput: '',
    // for card language validation
    selectedCardLang: CUSTOM_CARD_LANG.zh,
  });
  const app = React.useContext(AppContext.Context);
  const {products} = app;
  const {dimension, tablet, mobile} = useDimension();
  const params = queryString.parse(props.location.search);
  const productId = params.id;

  React.useEffect(() => {
    const _fetch = async () => {
      AppActions.setLoading(true);
      await delay(800);
      const _product = products.find((p) => p.id === productId);
      if (!_product) {
        return;
      }
      setProduct(_product);
      setValues({
        specItemId: null,
        logisticType: _product.allow_logistic_type[0] || undefined,
        quantity: 1,
        date: '',
        pickUpTime: '',
        customCardInput: '',
        // for card language validation
        selectedCardLang: CUSTOM_CARD_LANG.zh,
      });
      AppActions.setLoading(false);
    };
    if (products.length > 0 && productId && !product) {
      _fetch();
    }
  }, [productId, products, product]);

  if (!product) {
    return null;
  }

  if (!product.published) {
    return (
      <Wrapper>
        <Result
          title="此商品不存在"
          extra={
            <Button
              onClick={() => {
                navigate('/');
              }}
              type="primary"
              key="product-not-published">
              返回
            </Button>
          }
        />
      </Wrapper>
    );
  }

  if (!ProductUtil.isProductAllowToBuy(product)) {
    return (
      <Wrapper>
        <Result
          title="此商品尚未開放購買"
          extra={
            <Button
              onClick={() => {
                navigate('/');
              }}
              type="primary"
              key="product-not-allow-to-buy">
              返回
            </Button>
          }
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Ant.Row gutter={1}>
        <Ant.Col xs={24} sm={24} md={24} lg={12}>
          <ProductImageCarousel
            images={product.images}
            ratio={4 / 6}
            itemExtraCss={'border-radius: 12px;'}
          />
          {!tablet && (
            <ProductAdditionalPurchasePanel
              product={product}
              values={values}
              setValues={setValues}
              showSectionTitle
            />
          )}
        </Ant.Col>
        <Ant.Col xs={24} sm={24} md={24} lg={1} />
        <Ant.Col xs={24} sm={24} md={24} lg={11}>
          <ProductConfigPanel
            product={product}
            values={values}
            setValues={setValues}
            additionalPurchasePanelComp={
              tablet ? (
                <ProductAdditionalPurchasePanel
                  showSectionTitle={false}
                  product={product}
                  values={values}
                  setValues={setValues}
                />
              ) : null
            }
          />
        </Ant.Col>
      </Ant.Row>
      <Ant.Col>
        <ProductInfoTabs product={product} style={{marginBottom: 55}} />
      </Ant.Col>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: var(--content-max-width);
  padding: 0px var(--content-padding);
  margin: 0 auto;
  width: 100%;
  padding-top: 45px;
`;

export default ProductPage;
